import React from "react";


const Footer = () => {
    const Tell = ({ number, ...props }) => {
        return (
            <a href={`tel:${number}`}>
                {props.children}
            </a>
        );
    }


    const isFlightsLoading = localStorage.getItem("loading")

    const Mailto = ({ email, ...props }) => {
        return (
            <a href={`mailto:${email}`}>
                {props.children}
            </a>
        );
    }

    const currentUrl = window.location.href
    return (
        <footer className="footer-wrapp p-4 text-white">
            <div className="container">
                <div className="row">
                    <div className="col-5">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="site-logo">
                                    { currentUrl.includes('airdelayed.com') 
                                        ? <img src={`${process.env.PUBLIC_URL}/partner-logos/airdelayed-white.svg`} alt="" className="airdelayed-logo"/>
                                        : currentUrl.includes('flightclaims.co.uk') 
                                            ? <div></div>
                                            : <img src={`${process.env.PUBLIC_URL}/img/site-logo-white.png`} alt=""  className="ftr-logo-block"/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 text-end">
                        <p style={{fontSize: '7px'}}>1.11.2</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
